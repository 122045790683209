import {
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILED,
  GET_STATES_SUCCESS,
  GET_STATES_FAILED,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILED
} from "./actionTypes";

const initialState = {
  addressSuccess: false,
  addressFailed: false,
  countries: [],
  states: [{label:'',value:''}],
  cities: []
}

const addressesReducer = (state = initialState, action) => {
  switch (action.type){
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries:action.countries
      }
    case GET_COUNTRIES_FAILED:
      return {
        ...state,
        success: action.failed
      }
    case GET_STATES_SUCCESS:
      return {
        ...state,
        states:action.states
      }
    case GET_STATES_FAILED:
      return {
        ...state,
        success: action.failed
      }
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        cities:action.cities
      }
    case GET_CITIES_FAILED:
      return {
        ...state,
        success: action.failed
      }
    default:
      return state;
  }
}

export default addressesReducer;