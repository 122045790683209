import { takeEvery, call, put } from 'redux-saga/effects';
import { API_BASE_URL } from '../../../src/configs/appConfig';
import axios from 'axios';


import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_STATES_SUCCESS,
  GET_STATES_FAILED,
  GET_COUNTRIES_FAILED,
  GET_CITIES,
  GET_STATES
} from "./actionTypes";

import { 
  getCountriesSuccess,
  getCountriesFailed,
  getStatesSuccess,
  getStatesFailed,
  getCitiesSuccess,
  getCitiesFailed
} from './actions';


export default function* AddressesSaga() {
  console.log("SAGA RAN!!")
  yield takeEvery(GET_COUNTRIES, getCountriesSaga)
  yield takeEvery(GET_STATES, getStatesSaga)
}

function* getCountriesSaga(action){
  console.log("IN SAGA:", action)
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.get, `${API_BASE_URL}address/countries`, { headers: headerParams })
    if (response.status === 200) {
      const {data} = response;
      console.log("DATA IN COUNTRIES SAGA:",data);
      const countries = [];
      const reducerData = {
        status:200,
        countries:data,
        success:true
      }
      yield put(getCountriesSuccess(reducerData))
    }
  } catch (err) {
    console.log("ERROR IS:",err);
    yield put(getCountriesFailed(true))
  }
}

function* getStatesSaga(action){
  console.log("IN SAGA:", action)
  const country = action.payload.value
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.get, `${API_BASE_URL}address/countries/states/${country}`, { headers: headerParams })
    if (response.status === 200) {
      const {data} = response;
      console.log("DATA IN STATES SAGA:",data);
      
      const reducerData = {
        status:200,
        states:data,
        success:true
      }
      yield put(getStatesSuccess(reducerData))
    }
  } catch (err) {
    console.log("ERROR IS:",err);
    yield put(getStatesFailed(true))
  }
}