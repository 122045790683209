import { takeEvery, call, put } from 'redux-saga/effects';
import { API_BASE_URL } from '../../../src/configs/appConfig';
import axios from 'axios';
import {
  INIT_INITIAL_CATALOG_VALUE,
  GET_AIRCRAFT_MODELS,
  GET_AIRCRAFT_ASSEMBLIES,
  GET_AIRCRAFT_PARTS,
  GET_AIRCRAFT_PARTS_SEARCH,
  SELECT_AIRCRAFT_PARTS,
  GET_AIRCRAFT_PARTS_CART,
  GET_AIRCRAFT_PARTS_CART_SUCCESS,
  GET_AIRCRAFT_PARTS_CART_FAILED,
  REMOVE_FROM_PARTS_CART,
  PARTS_CART_UPDATE_QTY,
  PARTS_CART_POST,
  PARTS_CART_POST_SUCCESS,
  PARTS_CART_POST_FAILED
} from "./actionTypes";
import { 
  initSavedCatalogValueSuccess,
  initSavedCatalogValueFailure,
  getAircraftModelsSuccess,
  getAircraftModelsFailed,
  getAircraftAssembliesSuccess,
  getAircraftAssembliesFailed,
  getAircraftPartsSuccess,
  getAircraftPartsFailed,
  getAircraftPartsSearchSuccess,
  getAircraftPartsSearchFailed,
  getPartsCart,
  getPartsCartSuccess,
  getPartsCartFailed,
  removeFromPartsCartSuccess,
  removeFromPartsCartFailed,
  selectAircraftPartsSuccess,
  selectAircraftPartsFailed,
  partsCartUpdateQtySuccess,
  partsCartUpdateQtyFailed,
  partsCartPostSuccess,
  partsCartPostFailed
 } from './actions';


export default function* PartsCatalogSaga() {
  console.log("SAGA RAN!!")
  yield takeEvery(INIT_INITIAL_CATALOG_VALUE, saveCatalogChoiceSaga)
  yield takeEvery(GET_AIRCRAFT_MODELS, getModelsSaga)
  yield takeEvery(GET_AIRCRAFT_ASSEMBLIES, getAssembliesSaga)
  yield takeEvery(GET_AIRCRAFT_PARTS, getPartsSaga)
  yield takeEvery(GET_AIRCRAFT_PARTS_SEARCH, getPartsSearchSaga)
  yield takeEvery(SELECT_AIRCRAFT_PARTS, postSelectedPart)
  yield takeEvery(GET_AIRCRAFT_PARTS_CART, getCartItems)
  yield takeEvery(REMOVE_FROM_PARTS_CART, removeCartItems)
  yield takeEvery(PARTS_CART_UPDATE_QTY, updateCartQty)
  yield takeEvery(PARTS_CART_POST, postPartsCart)
}

function* saveCatalogChoiceSaga(action) {
  console.log("IN SAGA:", action)
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = {
      status:200,
      catalogValue:action.catalogValue,
      success:true
    }
    console.log("RESPONSE FROM API:", action);
    if (response.status === 200) {
      yield put(initSavedCatalogValueSuccess(response))
    }
  } catch (err) {
    yield put(initSavedCatalogValueFailure(true))
  }
}

function* getModelsSaga(action){
  console.log("IN SAGA:", action)
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.get, `${API_BASE_URL}models/`, { headers: headerParams })
    if (response.status === 200) {
      const {data} = response;
      const modelsList = [];
      for(var item in data){
        console.log("ITEM IS:",data[item])
        if(data[item].model_id.length > 0){modelsList.push(data[item].model_id)}
      }
      const reducerData = {
        status:200,
        aircraftModels:modelsList,
        success:true
      }
      yield put(getAircraftModelsSuccess(reducerData))
    }
  } catch (err) {
    console.log("ERROR IS:",err);
    yield put(getAircraftModelsFailed())
  }
}

function* getAssembliesSaga(action){
  console.log("IN SAGA:", action)
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.get, `${API_BASE_URL}models/${action.payload}/assemblies`, { headers: headerParams })
    
    if (response.status === 200) {
      const {data} = response;
      console.log("DATA IN SAGA:",data);
      const assembliesList = [];
      // for(var item in data){
      //   console.log("ITEM IS:",data[item])
      //   try{
      //     if(data[item].assembly.length > 0){assembliesList.push(data[item].assembly)}
      //   }catch(err){
      //     console.log(err);
      //   }
        
      // }
      const reducerData = {
        status:200,
        aircraftAssemblies:data,
        success:true
      }
      yield put(getAircraftAssembliesSuccess(reducerData))
    }
  } catch (err) {
    console.log("ERROR IS:",err);
    yield put(getAircraftAssembliesFailed())
  }
}

function* getPartsSaga(action){
  console.log("IN SAGA:", action)
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.get, `${API_BASE_URL}models/${action.payload.model_id}/assemblies/${action.payload.assembly}/parts`, { headers: headerParams })
    console.log("RESPONSE IN PARTS:",response)
    if (response.status === 200) {
      const {data} = response;
      
      const reducerData = {
        status:200,
        aircraftParts:data,
        success:true
      }
      yield put(getAircraftPartsSuccess(reducerData))
    }
  } catch (err) {
    console.log("ERROR IS:",err);
    yield put(getAircraftPartsFailed())
  }
}

function* getPartsSearchSaga(action){
  console.log("IN SAGA:", action)
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.get, `${API_BASE_URL}models/assemblies/parts/search/${action.payload.partFuzzySearch}`, { headers: headerParams })
    console.log("RESPONSE IN PARTS:",response)
    if (response.status === 200) {
      const {data} = response;
      
      const reducerData = {
        status:200,
        aircraftParts:data,
        success:true
      }
      yield put(getAircraftPartsSearchSuccess(reducerData))
    }
  } catch (err) {
    console.log("ERROR IS:",err);
    yield put(getAircraftPartsSearchFailed())
  }
}

function* postSelectedPart(action){
  console.log("IN SAGA:", action.payload.sessionId)
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.post, `${API_BASE_URL}cart`, { 
      headers: headerParams ,
      parts: action.payload.parts,
      sessionId: action.payload.sessionId
    })
    console.log("RESPONSE IN PARTS:",response)
    if (response.status === 200) {
      const {data} = response;
      
      
      const reducerData = {
        status:200,
        success:true
      }
      yield put(selectAircraftPartsSuccess(reducerData))
    }
  } catch (err) {
    console.log("ERROR IS:",err);
    yield put(selectAircraftPartsFailed())
  }
}

function* getCartItems(action){
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.get, `${API_BASE_URL}cart/${action.payload.email}`, { 
      headers: headerParams 
    })
    
    if (response.status === 200) {
      const {data} = response;
      console.log("RESPONSE IN PARTS SAGA:",data)
      const reducerData = {
        status:200,
        success:true,
        cartParts:data
      }
      yield put(getPartsCartSuccess(reducerData))
    }
  } catch (err) {
    console.log("ERROR IS:",err);
    yield put(getPartsCartFailed())
  }
}        

function* removeCartItems(action){
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.get, `${API_BASE_URL}cart/remove/${action.payload.itemId}`, { 
      headers: headerParams 
    })
    
    if (response.status === 200) {
      const {data} = response;
      console.log("RESPONSE IN PARTS SAGA:",data)
      const reducerData = {
        status:200,
        success:true,
        cartParts:data
      }
      yield put(removeFromPartsCartSuccess(reducerData))
    }
  } catch (err) {
    console.log("ERROR IS:",err);
    yield put(removeFromPartsCartFailed())
  }
}     

function* updateCartQty(action){
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.post, `${API_BASE_URL}cart/qty`, { 
      headers: headerParams ,
      itemId: action.payload.itemId,
      sessionId: action.payload.sessionId,
      qty:action.payload.qty
    })
    
    if (response.status === 200) {
      const {data} = response;
      console.log("RESPONSE IN PARTS SAGA:",data)
      const reducerData = {
        status:200,
        success:true,
        cartParts:data
      }
      yield put(partsCartUpdateQtySuccess(reducerData))
    }
  } catch (err) {
    console.log("ERROR IS:",err);
    yield put(partsCartUpdateQtyFailed())
  }
}    

function* postPartsCart(action) {
  console.log("IN SAGA:", action)
  const headerParams = {
    'content-type': 'application/json'
  }
  try {
    const response = yield call(axios.post, `${API_BASE_URL}cart/inquiry`, { 
      headers: headerParams,
      sessionId: action.payload.sessionId,
      partsCart:action.payload.partsPostObject
    })
    
    if (response.status === 200) {
      const {data} = response;
      console.log("RESPONSE IN PARTS SAGA:",data)
      const reducerData = {
        status:200,
        success:true
      }
      yield put(partsCartUpdateQtySuccess(reducerData))
    }
  } catch (err) {
    console.log("ERROR IS:",err);
    const reducerData = {
      status:400,
      success:false
    }
    yield put(partsCartUpdateQtyFailed(reducerData))
  }
}