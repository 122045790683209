export const INIT_INITIAL_CATALOG_VALUE             = 'INIT_INITIAL_CATALOG_VALUE';
export const SAVE_INITIAL_CATALOG_SUCCESS           = 'SAVE_INITIAL_CATALOG_SUCCESS';
export const SAVE_INITIAL_CATALOG_FAILED            = 'SAVE_INITIAL_CATALOG_FAILED';
export const GET_AIRCRAFT_MODELS                    = 'GET_AIRCRAFT_MODELS';
export const GET_AIRCRAFT_MODELS_SUCCESS            = 'GET_AIRCRAFT_MODELS_SUCCESS';
export const GET_AIRCRAFT_MODELS_FAILED             = 'GET_AIRCRAFT_MODELS_FAILED';
export const GET_AIRCRAFT_ASSEMBLIES                = 'GET_AIRCRAFT_ASSEMBLIES';
export const GET_AIRCRAFT_ASSEMBLIES_SUCCESS        = 'GET_AIRCRAFT_ASSEMBLIES_SUCCESS';
export const GET_AIRCRAFT_ASSEMBLIES_FAILED         = 'GET_AIRCRAFT_ASSEMBLIES_FAILED';
export const GET_AIRCRAFT_PARTS                     = 'GET_AIRCRAFT_PARTS';
export const GET_AIRCRAFT_PARTS_SUCCESS             = 'GET_AIRCRAFT_PARTS_SUCCESS';
export const GET_AIRCRAFT_PARTS_FAILED              = 'GET_AIRCRAFT_PARTS_FAILED';
export const GET_AIRCRAFT_PARTS_SEARCH              = 'GET_AIRCRAFT_PARTS_SEARCH';
export const GET_AIRCRAFT_PARTS_SEARCH_SUCCESS      = 'GET_AIRCRAFT_PARTS_SEARCH_SUCCESS';
export const GET_AIRCRAFT_PARTS_SEARCH_FAILED       = 'GET_AIRCRAFT_PARTS_SEARCH_FAILED';
export const SELECT_AIRCRAFT_PARTS                  = 'SELECT_AIRCRAFT_PARTS';
export const SELECT_AIRCRAFT_PARTS_SUCCESS          = 'SELECT_AIRCRAFT_PARTS_SUCCESS';
export const SELECT_AIRCRAFT_PARTS_FAILED           = 'SELECT_AIRCRAFT_PARTS_FAILED';
export const GET_AIRCRAFT_PARTS_CART                = 'GET_AIRCRAFT_PARTS_CART';
export const GET_AIRCRAFT_PARTS_CART_SUCCESS        = 'GET_AIRCRAFT_PARTS_CART_SUCCESS';
export const GET_AIRCRAFT_PARTS_CART_FAILED         = 'GET_AIRCRAFT_PARTS_CART_FAILED';
export const REMOVE_FROM_PARTS_CART                 = 'REMOVE_FROM_PARTS_CART';
export const REMOVE_FROM_PARTS_CART_SUCCESS         = 'REMOVE_FROM_PARTS_CART_SUCCESS';
export const REMOVE_FROM_PARTS_CART_FAILED          = 'REMOVE_FROM_PARTS_CART_FAILED';
export const PARTS_CART_UPDATE_QTY                  = 'PARTS_CART_UPDATE_QTY';
export const PARTS_CART_UPDATE_QTY_SUCCESS          = 'PARTS_CART_UPDATE_QTY_SUCCESS';
export const PARTS_CART_UPDATE_QTY_FAILED           = 'PARTS_CART_UPDATE_QTY_FAILED';
export const PARTS_CART_POST                        = 'PARTS_CART_POST';
export const PARTS_CART_POST_SUCCESS                = 'PARTS_CART_POST_SUCCESS';
export const PARTS_CART_POST_FAILED                 = 'PARTS_CART_POST_FAILED';