import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILED,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_FAILED,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILED
} from "./actionTypes";

export function getCountries(assemblyObject){
  return {
    type:GET_COUNTRIES,
    payload:assemblyObject
  }
}

export function getCountriesSuccess(response){
  return {
    type: GET_COUNTRIES_SUCCESS,
    countries:response.countries,
    success:response.success
  }
}

export function getCountriesFailed(failed){
  return {
    type: GET_COUNTRIES_FAILED,
    failed
  }
}

export function getStates(country){
  return {
    type:GET_STATES,
    payload:country
  }
}

export function getStatesSuccess(response){
  return {
    type: GET_STATES_SUCCESS,
    states:response.states,
    success:response.success
  }
}

export function getStatesFailed(failed){
  return {
    type: GET_STATES_FAILED,
    failed
  }
}

export function getCities(country){
  return {
    type:GET_CITIES,
    payload:country
  }
}

export function getCitiesSuccess(response){
  return {
    type: GET_CITIES_SUCCESS,
    cities:response.cities,
    success:response.success
  }
}

export function getCitiesFailed(failed){
  return {
    type: GET_CITIES_FAILED,
    failed
  }
}