import { trackOrSetValue } from "@testing-library/user-event/dist/cjs/document/trackValue.js";
import {
  SAVE_INITIAL_CATALOG_SUCCESS,
  SAVE_INITIAL_CATALOG_FAILED,
  GET_AIRCRAFT_MODELS_SUCCESS,
  GET_AIRCRAFT_MODELS_FAILED,
  GET_AIRCRAFT_ASSEMBLIES_SUCCESS,
  GET_AIRCRAFT_ASSEMBLIES_FAILED,
  GET_AIRCRAFT_PARTS_SUCCESS,
  GET_AIRCRAFT_PARTS_FAILED,
  GET_AIRCRAFT_PARTS_SEARCH_SUCCESS,
  GET_AIRCRAFT_PARTS_SEARCH_FAILED,
  SELECT_AIRCRAFT_PARTS,
  SELECT_AIRCRAFT_PARTS_SUCCESS,
  SELECT_AIRCRAFT_PARTS_FAILED,
  GET_AIRCRAFT_PARTS_CART,
  GET_AIRCRAFT_PARTS_CART_SUCCESS,
  GET_AIRCRAFT_PARTS_CART_FAILED,
  REMOVE_FROM_PARTS_CART,
  REMOVE_FROM_PARTS_CART_SUCCESS,
  REMOVE_FROM_PARTS_CART_FAILED,
  PARTS_CART_UPDATE_QTY,
  PARTS_CART_UPDATE_QTY_SUCCESS,
  PARTS_CART_UPDATE_QTY_FAILED
} from "./actionTypes";

const initialState = {
  success: false,
  failed: false,
  initialCatalog:"aviat_ac180",
  models:[],
  assemblies:[],
  parts:[],
  partsSelected:[],
  partsCart:[],
  partsLastUpdated:""
}

const partsCatalogReducer = (state = initialState, action) => {
  console.log("IN REDUCER:",action)
  switch (action.type) {
    case SAVE_INITIAL_CATALOG_SUCCESS:
      return {
        ...state,
        initialCatalog: action.catalogValue
      }
    case SAVE_INITIAL_CATALOG_FAILED:
      return {
        ...state,
        success: action.failed
      }
    case GET_AIRCRAFT_MODELS_SUCCESS:
      return {
        ...state,
        models: action.aircraftModels
      }
    case GET_AIRCRAFT_MODELS_FAILED:
      return {
        ...state,
        success: action.failed
      }
    case GET_AIRCRAFT_ASSEMBLIES_SUCCESS:
      return {
        ...state,
        assemblies: action.aircraftAssemblies
      }
    case GET_AIRCRAFT_ASSEMBLIES_FAILED:
      return {
        ...state,
        success: action.failed
      }
    case GET_AIRCRAFT_PARTS_SUCCESS:
      return {
        ...state,
        parts: action.aircraftParts
      }
    case GET_AIRCRAFT_PARTS_FAILED:
      return {
        ...state,
        success: action.failed
      }
    case GET_AIRCRAFT_PARTS_SEARCH_SUCCESS:
      return {
        ...state,
        parts: action.aircraftParts
      }
    case GET_AIRCRAFT_PARTS_SEARCH_FAILED:
      return {
        ...state,
        success: action.failed
      }
    case SELECT_AIRCRAFT_PARTS:
      return {
        ...state,
        partsSelected: action.payload
      }
    case SELECT_AIRCRAFT_PARTS_SUCCESS:
      return {
        ...state,
        partsLastUpdated: action.payload.partsLastUpdated
      }
    case SELECT_AIRCRAFT_PARTS_FAILED:
      return {
        ...state,
        partsSelected: action.payload
      }
    case GET_AIRCRAFT_PARTS_CART_SUCCESS:
      return {
        ...state,
        success:action.success,
        partsCart: action.partsCart
      }
    case GET_AIRCRAFT_PARTS_CART_FAILED:
      return {
        ...state,
        success: action.failed
      }
      
    case REMOVE_FROM_PARTS_CART:
      return {
        ...state,
      }
    case REMOVE_FROM_PARTS_CART_SUCCESS:
      return {
        ...state,
        success: action.success,
        partsLastUpdated: action.payload.partsLastUpdated
      }
    case REMOVE_FROM_PARTS_CART_FAILED:
      return {
        ...state,
        success: action.failed
      }
    case PARTS_CART_UPDATE_QTY:
      return {
        ...state,
      }
    case PARTS_CART_UPDATE_QTY_SUCCESS:
      return {
        ...state,
        success: true,
      }
    case PARTS_CART_UPDATE_QTY_FAILED:
      return {
        ...state,
        success: false
      }
    default:
      return state;
  }
}

export default partsCatalogReducer;