import {
  INIT_INITIAL_CATALOG_VALUE,
  SAVE_INITIAL_CATALOG_SUCCESS,
  SAVE_INITIAL_CATALOG_FAILED,
  GET_AIRCRAFT_MODELS,
  GET_AIRCRAFT_MODELS_SUCCESS,
  GET_AIRCRAFT_MODELS_FAILED,
  GET_AIRCRAFT_ASSEMBLIES,
  GET_AIRCRAFT_ASSEMBLIES_SUCCESS,
  GET_AIRCRAFT_ASSEMBLIES_FAILED,
  GET_AIRCRAFT_PARTS,
  GET_AIRCRAFT_PARTS_SUCCESS,
  GET_AIRCRAFT_PARTS_FAILED,
  GET_AIRCRAFT_PARTS_SEARCH,
  GET_AIRCRAFT_PARTS_SEARCH_SUCCESS,
  GET_AIRCRAFT_PARTS_SEARCH_FAILED,
  SELECT_AIRCRAFT_PARTS,
  SELECT_AIRCRAFT_PARTS_SUCCESS,
  SELECT_AIRCRAFT_PARTS_FAILED,
  GET_AIRCRAFT_PARTS_CART,
  GET_AIRCRAFT_PARTS_CART_SUCCESS,
  GET_AIRCRAFT_PARTS_CART_FAILED,
  REMOVE_FROM_PARTS_CART,
  REMOVE_FROM_PARTS_CART_SUCCESS,
  REMOVE_FROM_PARTS_CART_FAILED,
  PARTS_CART_UPDATE_QTY,
  PARTS_CART_UPDATE_QTY_SUCCESS,
  PARTS_CART_UPDATE_QTY_FAILED,
  PARTS_CART_POST,
  PARTS_CART_POST_SUCCESS,
  PARTS_CART_POST_FAILED
} from "./actionTypes";

export function initCatalogValue(catalogValue){
  console.log("ACTION FIRED!!!:",catalogValue);
  return {
    type: INIT_INITIAL_CATALOG_VALUE,
    catalogValue
  }
}

export function initSavedCatalogValueSuccess(response){
  console.log("SECOND ACTION:",response)
  return {
    type: SAVE_INITIAL_CATALOG_SUCCESS,
    catalogValue:response.catalogValue,
    success:response.success
  }
}

export function initSavedCatalogValueFailure(failed){
  return {
    type: SAVE_INITIAL_CATALOG_FAILED,
    failed
  }
}

export function getAircraftModels(){
  return {
    type:GET_AIRCRAFT_MODELS
  }
}

export function getAircraftModelsSuccess(response){
  return {
    type: GET_AIRCRAFT_MODELS_SUCCESS,
    aircraftModels:response.aircraftModels,
    success:response.success
  }
}

export function getAircraftModelsFailed(failed){
  return {
    type: GET_AIRCRAFT_MODELS_FAILED,
    failed
  }
}

export function getAircraftAssemblies(model_id){
  return {
    type:GET_AIRCRAFT_ASSEMBLIES,
    payload:model_id
  }
}

export function getAircraftAssembliesSuccess(response){
  return {
    type: GET_AIRCRAFT_ASSEMBLIES_SUCCESS,
    aircraftAssemblies:response.aircraftAssemblies,
    success:response.success
  }
}

export function getAircraftAssembliesFailed(failed){
  return {
    type: GET_AIRCRAFT_ASSEMBLIES_FAILED,
    failed
  }
}

export function getAircraftParts(assemblyObject){
  return {
    type:GET_AIRCRAFT_PARTS,
    payload:assemblyObject
  }
}

export function getAircraftPartsSuccess(response){
  return {
    type: GET_AIRCRAFT_PARTS_SUCCESS,
    aircraftParts:response.aircraftParts,
    success:response.success
  }
}

export function getAircraftPartsFailed(failed){
  return {
    type: GET_AIRCRAFT_PARTS_FAILED,
    failed
  }
}

export function getAircraftPartsSearch(partPartial){
  return {
    type:GET_AIRCRAFT_PARTS_SEARCH,
    payload:partPartial
  }
}

export function getAircraftPartsSearchSuccess(response){
  return {
    type: GET_AIRCRAFT_PARTS_SEARCH_SUCCESS,
    aircraftParts:response.aircraftParts,
    success:response.success
  }
}

export function getAircraftPartsSearchFailed(failed){
  return {
    type: GET_AIRCRAFT_PARTS_SEARCH_FAILED,
    failed
  }
}

export function selectAircraftParts(parts,sessionId){
  return {
    type:SELECT_AIRCRAFT_PARTS,
    payload:{parts,sessionId}
  }
}

export function selectAircraftPartsSuccess(response){
  return {
    type: SELECT_AIRCRAFT_PARTS_SUCCESS,
    success:response.success,
    payload:{partsLastUpdated:Date.now()}
  }
}

export function selectAircraftPartsFailed(failed){
  return {
    type: SELECT_AIRCRAFT_PARTS_FAILED,
    failed
  }
}

export function getPartsCart(email){
  console.log("GET PARTS CART FIRED!!!");
  return{
    type:GET_AIRCRAFT_PARTS_CART,
    payload:{email}
  }
}

export function getPartsCartSuccess(response){
  return{
    type:GET_AIRCRAFT_PARTS_CART_SUCCESS,
    partsCart:response.cartParts,
    success:response.success
  }
}

export function getPartsCartFailed(){
  return{
    type:GET_AIRCRAFT_PARTS_CART_FAILED
  }
}

export function removeFromPartsCart(itemId){
  return {
    type:REMOVE_FROM_PARTS_CART,
    payload:{itemId}
  }
}

export function removeFromPartsCartSuccess(response){
  return{
    type:REMOVE_FROM_PARTS_CART_SUCCESS,
    success:response.success,
    payload:{partsLastUpdated:Date.now()}
  }
}

export function removeFromPartsCartFailed(){
  return{
    type:REMOVE_FROM_PARTS_CART_FAILED
  }
}

export function partsCartUpdateQty(itemId,qty,sessionId){
  return {
    type:PARTS_CART_UPDATE_QTY,
    payload:{itemId,qty,sessionId}
  }
}

export function partsCartUpdateQtySuccess(response){
  return{
    type:PARTS_CART_UPDATE_QTY_SUCCESS,
    success:response.success,
    payload:{partsLastUpdated:Date.now()}
  }
}

export function partsCartUpdateQtyFailed(){
  return{
    type:PARTS_CART_UPDATE_QTY_FAILED
  }
}

export function partsCartPost(partsPostObject,sessionId){
  return{
    type:PARTS_CART_POST,
    payload:{partsPostObject,sessionId}
  }
}

export function partsCartPostSuccess(response){
  return{
    type:PARTS_CART_POST_SUCCESS
  }
}

export function partsCartPostFailed(){
  return{
    type:PARTS_CART_POST_FAILED
  }
}

